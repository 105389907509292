/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.datepicker {
    top: 0;
    left: 0;
    padding: 4px;
    margin-top: 1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    /*.dow {
    border-top: 1px solid #ddd !important;
  }*/
}

.datepicker:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px;
}

.datepicker:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
    top: -6px;
    left: 7px;
}

.datepicker>div {
    display: none;
}

.datepicker table {
    width: 100%;
    margin: 0;
}

.datepicker td,
.datepicker th {
    text-align: center;
    padding: 7px 10px;
}

.datepicker td.day:hover {
    background: #eeeeee;
    cursor: pointer;
}

.datepicker td.day.disabled {
    color: #eeeeee;
}

.datepicker td.old,
.datepicker td.new {
    color: #999999;
}

.datepicker td.active,
.datepicker td.active:hover {
    color: #ffffff;
    background-color: #6EAD2A;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker td.active:hover,
.datepicker td.active:hover:hover,
.datepicker td.active:focus,
.datepicker td.active:hover:focus,
.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active,
.datepicker td.active.disabled,
.datepicker td.active:hover.disabled,
.datepicker td.active[disabled],
.datepicker td.active:hover[disabled] {
    color: #ffffff;
    background-color: #6EAD2A;
    *background-color: #6EAD2A;
}

.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active {
    background-color: #003399 \9;
}

.datepicker td span {
    display: block;
    width: 47px;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 2px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.datepicker td span:hover {
    background: #6EAD2A;
}

.datepicker td span.active {
    color: #ffffff;
    background-color: #6EAD2A;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker td span.active:hover,
.datepicker td span.active:focus,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
    color: #ffffff;
    background-color: #0044cc;
    *background-color: #003bb3;
}

.datepicker td span.active:active,
.datepicker td span.active.active {
    background-color: #003399 \9;
}

.datepicker td span.old {
    color: #999999;
}

.datepicker th.switch {
    width: 145px;
}

.datepicker th.next,
.datepicker th.prev {
    font-size: 21px;
}

.datepicker thead tr:first-child th {
    cursor: pointer;
}

.datepicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
}