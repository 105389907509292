.head {
  padding: 5px 0px;
  margin: 5px 0px 5px 0px;
}
.context {
  margin-bottom: 5px;
  margin-top: 10px;
  border-bottom: 3px solid #b3d7c5 !important;
  min-height: 189px;
  background-color: #fff;
  border-radius: 6px;
  text-align: left;
}
.msg-context {
  min-height: 70px !important;
}
.message {
  background: #fff url() 99% 98% no-repeat;
  padding: 10px 10px 11px 10px;
  border: 1px solid #b3d7c5;
  border-radius: 5px;
  height: 100px;
  box-shadow: 0 0 5px #aff2d1;
}
.message p {
  font-size: 12px;
  color: #333c31;
  font-family: arial;
  letter-spacing: 0.03em;
  text-align: justify;
  margin: 0px px;
}
.message p img {
  vertical-align: text-top;
  float: left;
  padding-right: 12px;
}
